export default [
  {
    id: "PEN",
    name: 'Pendiente',
    detail: '',
    summary: 'Pendiente',
    color: 'yellowLight',
    icon: 'mdi-clock '
  },
  {
    id: "APR",
    name: 'Aceptado',
    detail: 'Acepta documento electrónico',
    summary: 'Aceptado',
    color: 'lightBlue',
    icon: 'mdi-check'
  },
  {
    id: "REJ",
    name: 'Rechzado',
    detail: 'Rechaza documento electrónico',
    summary: 'Rechazado',
    color: 'redLight',
    icon: 'mdi-close'
  }
]
  // {
  //   id: "PEN",
  //   name: 'Pendiente',
  //   detail: '',
  //   summary: 'Pendiente',
  //   color: 'yellowLight',
  //   icon: 'mdi-clock '
  // },
  // {
  //   id: "ERM",
  //   name: 'Aceptado',
  //   detail: 'Otorga Recibo de Mercaderías o Servicios',
  //   summary: 'Aceptado',
  //   color: 'lightBlue',
  //   icon: 'mdi-check'
  // },
  // {
  //   id: "ACD",
  //   name: 'Aceptado',
  //   detail: 'Acepta Contenido del Documento',
  //   summary: 'Aceptado',
  //   color: 'lightBlue',
  //   icon: 'mdi-check'
  // },
  // {
  //   id: "RCD",
  //   name: 'Reclamado',
  //   detail: 'Reclamo al Contenido del Documento',
  //   summary: 'Rechazado',
  //   color: 'redLight',
  //   icon: 'mdi-close'
  // },
  // {
  //   id: "RFP",
  //   name: 'Reclamado',
  //   detail: 'Reclamo por Falta Parcial de Mercaderías',
  //   summary: 'Rechazado',
  //   color: 'redLight',
  //   icon: 'mdi-close'
  // },
  // {
  //   id: "RFT",
  //   name: 'Reclamado',
  //   detail: 'Reclamo por Falta Total de Mercaderías',
  //   summary: 'Rechazado',
  //   color: 'redLight',
  //   icon: 'mdi-close'
  // }