<template>
  <v-col cols="12" class="pt-0 px-0">
    <v-skeleton-loader class="bgPanel rounded-0" type="image" height="25" />
    <div class="d-flex mt-2 px-5">
      <div class="align-stretch">
        <v-skeleton-loader class="bgPanel mt-6" type="image" height="20" width="207" style="border-radius: 3px"/>
        <v-skeleton-loader class="bgPanel mt-2" type="image" height="20" width="264" style="border-radius: 3px" />
      </div>
      <v-spacer />
      <div>
        <v-skeleton-loader class="bgPanel mt-6" type="image" height="60" width="165" style="border-radius: 3px"/>
      </div>
    </div>
    <div class="d-flex mt-0 px-5">
      <div class="align-stretch">
        <v-skeleton-loader class="bgPanel mt-6" type="image" height="20" width="207" style="border-radius: 3px"/>
        <v-skeleton-loader class="bgPanel mt-2" type="image" height="20" width="264" style="border-radius: 3px" />
        <v-skeleton-loader class="bgPanel mt-2" type="image" height="20" width="264" style="border-radius: 3px" />
      </div>
      <v-spacer />
      <div>
        <v-skeleton-loader class="bgPanel mt-6" type="image" height="20" width="207" style="border-radius: 3px" />
      </div>
    </div>
    <v-skeleton-loader class="bgPanel mt-6 mb-8 px-5" type="image" height="57" style="border-radius: 3px"/>
  </v-col>
</template>
<script>
  export default {
    data: () => ({
      attrs: {
        boilerplate: true,
        elevation: 0
      }
    })
  }
</script>