<template>
  <v-row v-scroll="onScroll">
    <!-- header -->
    <mini-header title="Emisión por lotes" :dense="true" :breadcrumbs="breadcrumbs" :hiddenTab="true" :scroll="scroll">
      <template v-slot:main>
        <v-btn class="mr-2" @click="retrieve" outlined>
          <v-img contain :src="require(`@/assets/icon-update${$vuetify.theme.dark ? '-dark' : ''}.svg`)" />
        </v-btn>
        <v-btn v-if="['PEN'].includes(instance.status) && $helpers.hasSomePermission(['delete_batch'])" @click="dialogDelete = true" outlined><v-icon left>mdi-login-variant</v-icon>Interrumpir proceso</v-btn>
      </template>
    </mini-header>
    <!-- end header -->

    <!-- loader -->
    <v-col cols="12" class="mt-20 px-0" v-if="loading && !loadingNextStage">
      <v-skeleton-loader class="rounded-t-md" transition="fade-transition" type="card-heading"/>
      <v-skeleton-loader class="rounded-0" transition="fade-transition" type="image"/>
      <v-skeleton-loader class="rounded-b-md" transition="fade-transition" type="table-tbody" />
    </v-col>
    <!-- end loader -->

    <v-col cols="12" class="mt-20 px-0" v-else>
      <v-card flat>
        <!-- stepper -->
        <v-col cols="11" class="text-center mx-auto pa-0 pb-4 pt-8" style="min-width: 800px;">
          <div class="d-flex justify-center" :class="{ 'pb-4' : !['PEN'].includes(instance.status)}">
            <div class="text-center" v-for="(step, index) in currentStepper" :key="index">
              <v-sheet class="transparent" min-width="110" style="z-index: 0; position: relative">
                <v-avatar size="30" :style="stepStyle(step, index)">
                  <v-icon
                    :color="stepIconColor(step, index)"
                    size="15">mdi-{{step.icon}}
                  </v-icon>
                </v-avatar>
                <span class="d-block mt-1 fontBody--text body-1 pt-1">{{ step.title }}</span>
              </v-sheet>
              <v-divider
                class="line-stepper"
                v-if="(stepperList.length - 1 !== index) && (index >= 0 && index < currentStepper.findIndex((item) => item.status.includes(instance.status)))" style="border: 1px solid var(--light-blue-primary);" />
              <v-divider
                class="line-stepper"
                v-else-if="currentStepper.length - 1 !== index"
                style="border: 1px solid var(--light-blue-grey);" />
            </div>
          </div>
          <v-btn
            class="mx-auto mt-4 subtitle-2 font-weight-medium"
            text
            color="primary"
            :ripple="false"
            @click="dialogProcess = true"
            v-if="['PEN'].includes(instance.status) && $helpers.hasSomePermission(['add_batch'])"
            >Confirmar documentos</v-btn>
        </v-col>
        <!-- end stepper -->
      </v-card>
      <v-card flat :class="[{'transparent' : documentsList.length}, 'mt-4']">
        <v-card-text class="pa-0">
          <v-row no-gutters>
            <v-col cols="12" class="pa-0 pt-2" :class="{'pb-2' : !documentsList.length}">
              <skeleton-list v-if="loadingBatchDocument" />
              <template v-else>
                <v-empty-state v-if="!documentsList.length" type="documents" id="documentos" :isFree="true" customClass="mt-n8" title="No existen documentos para mostrar" />
                <template v-else>
                  <v-data-table
                    :class="[{'fixed-table' : $store.state.base.isExpandNavigationDrawer}, 'mt-n2']"
                    :headers="currentHeader"
                    :items="documentsList"
                    :items-per-page="pagination"
                    hide-default-header
                    disable-sort
                    item-key="id"
                    hide-default-footer
                    mobile-breakpoint="300"
                    @click:row="(documentsList) => goTo(documentsList.id)"
                    :height="heightTable"
                    fixed-header
                    style="cursor: pointer"
                  >
                    <!-- header -->
                    <template v-slot:header="{ props: { headers } }">
                      <VTableHeaders :headers="headers" />
                      <v-divider style="position: absolute; z-index: 2; margin-top: -6px; margin-left: -5px; min-width: 100%" />
                    </template>
                    <!-- end header -->
                    <template v-slot:[`item.customer_name`]="{item}">
                      <router-link :to="`/documents/${item.id}`" class="fontLinkTable--text text-decoration-none">
                        <span v-if="item.customer_name" class="d-inline-block text-truncate body-2" style="max-width: 100%;">{{item.customer_name}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                        <span class="caption fontDraw--text d-block" v-if="item.customer_tax_id">{{$t('generals.RUT')}} {{item?.customer_tax_id}}</span>
                        <span class="body-2 text--disabled font-italic" v-else>Sin especificar</span>
                      </router-link>
                    </template>
                    <template v-slot:[`item.type`]="{item}">
                      <router-link v-if="$route.name === 'DocumentsBatchRetrieve'" :to="{ name: 'DocumentsRetrieve', params: {...$route.params, id: item.id} }" class="fontLinkTable--text text-decoration-none">
                        <span class="body-2" style="white-space: normal !important">{{item.type | nameDocumentsType}}</span>
                      </router-link>
                      <router-link v-else :to="{ name: 'DocumentsRetrieve', params: {...$route.params, id: item.id} }" class="fontLinkTable--text text-decoration-none">
                        <span class="body-2" style="white-space: normal !important">{{item.type | nameDocumentsType}}</span>
                      </router-link>
                    </template>
                    <template v-slot:[`item.number`]="{item}">
                      <span class="body-2">{{ item.number }}</span>
                    </template>
                    <template v-slot:[`item.date`]="{item}">
                      <span class="body-2">{{item.date | dateTimeSecond}}</span>
                    </template>
                    <template v-slot:[`item.created`]="{item}">
                      <span class="body-2">{{item.created | dateTimeSecond}}</span>
                    </template>
                    <template v-slot:[`item.status`]="{item}">
                      <chip-status :status="item.status ? item.status : 'CRE'" :detail="item?.agency_status_detail_list || item?.agency_status_info ? `${setTooltip(item.agency_status_detail_list) || item.agency_status_info}` : null" :isTooltip="true" :isActivePopover="true" />
                    </template>
                    <template v-slot:[`item.amount_total`]="{item}">
                      <span class="body-2">{{item.amount_total}}</span>
                    </template>
                    <template v-slot:[`item.amount_total`]="{item}">
                      <span class="body-2">{{item.amount_total}}</span>
                      <span class="fontDraw--text caption ml-1">{{item?.currency || $store?.state?.auth?.account.base_currency.id}}</span>
                    </template>
                    <template v-slot:[`item.actions`]="{item}">
                      <v-menu close-on-click close-on-content-click offset-y left :nudge-left="10" :nudge-bottom="10" transition="slide-y-transition">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn class="mr-n4" v-bind="attrs" v-on="on" text @click="current = item" x-small><v-icon color="fontBody" size="24">mdi-dots-vertical</v-icon></v-btn>
                        </template>
                        <v-list class="pa-0">
                          <v-list-item class="px-3" @click="renderDocument2('documents', null, 'pdf', item.id), resetPDF += 1" :ripple="false">
                            <v-list-item-title class="body-2 d-flex align-center"><v-icon color="secondary" size="20" left>mdi-text-box</v-icon>Ver PDF</v-list-item-title>
                          </v-list-item>
                          <v-list-item class="px-3" @click="downloadFile2('documents', `${item.type}_${item.number}`, null, 'xml', item.id)" :ripple="false">
                            <v-list-item-title class="body-2 d-flex align-center"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar documento</v-list-item-title>
                          </v-list-item>
                          <v-list-item v-if="['APR', 'OBS'].includes(item.status)" class="px-3" @click="downloadFile2('documents', `${item.type}_${item.number}`, null, 'voucher', item.id)" :ripple="false">
                            <v-list-item-title class="body-2 d-flex align-center"><v-icon color="secondary" size="20" left>mdi-code-braces-box</v-icon>Descargar CDR</v-list-item-title>
                          </v-list-item>
                        </v-list>
                      </v-menu>
                    </template>
                  </v-data-table>
                  <template>
                    <v-divider />
                    <VMainPagination :count="countDocuments" redirect="DocumentsList" @isActive="getDocumentByBatch" />
                  </template>
                </template>
              </template>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>

    <!-- dialog confirm process-->
    <v-dialog v-model="dialogProcess" width="650" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Confirmar documentos</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogProcess=false, confirm = ''" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-confirm.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-3 pl-1">
              <span class="d-block text-body-1">Al continuar, no hay vuelta atrás ya que los documentos se enviarán a Sunat.</span>
              <span class="d-block text-body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogProcess=false, confirm = ''" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="handleConfirm" :disabled="confirm.toLowerCase() !== 'confirmar' ">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm process -->

    <!-- dialog PDF -->
    <v-dialog v-model="dialogRandomReviewPDF" width="700" persistent no-click-animation>
      <v-card min-height="733">
        <template>
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--text font-weight-semibold">Revisión aleatoria</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogRandomReviewPDF=false" icon small retain-focus-on-click><v-icon color="fontBody" size="20" >mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
          <v-divider />
        </template>
        <v-card-text class="pa-0" style="min-height: 635px !important;">
          <v-alert class="body-2 pl-5 inf-alert primary--text" v-show="!loaderRender && renderUrl" :height="34" dense text outlined color="#BED2F0" tile>Esta visualización es una vista previa del documento, por lo que no es válido legalmente.</v-alert>
          <v-row no-gutters align="center" justify="center" style="height: 600px">
            <v-col cols="12" class="py-0 px-4 ma-0" v-if="loaderRender">
              <v-skeleton-loader class="rounded-0 pa-0" type="table" />
            </v-col>
            <v-col v-else-if="!renderUrl">
              <v-empty-state class="mt-4" title="PDF no disponible" customClass="mt-6" :height="100" type="pdf" />
            </v-col>
          </v-row>
          <div v-show="!loaderRender && renderUrl" id="render"></div>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-btn outlined @click="index > 0 ? handlePDF('previous') : ''" :disabled="index === 0">Anterior</v-btn>
          <v-spacer />
          <v-btn outlined @click="index >= 0  && index < (instance.random_preview.length - 1) ? handlePDF() : ''" :disabled="index === (instance.random_preview || []).length - 1 || !renderUrl">Siguiente</v-btn>
          <v-btn color="primary" dark @click="dialogRandomReviewPDF=false">Aceptar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog pdf -->

    <!-- document detail dialog -->
      <DocumentRowDetail v-if="dialogDetail" :item="current" @close="dialogDetail = false" />
    <!-- end document detail dialog -->

    <!-- dialog confirm delete -->
    <v-dialog v-model="dialogDelete" width="650" persistent no-click-animation overlay-color="overlay">
      <v-card>
        <v-card-title class="pa-0">
          <v-toolbar color="transparent" flat height="46">
            <v-toolbar-title class="px-5 subtitle-2 fontBody--secondary font-weight-semibold">Interrumpir proceso</v-toolbar-title>
            <v-spacer />
            <v-toolbar-items>
              <v-row align="center" justify="center" class="mr-3">
                <v-btn @click="dialogDelete=false, confirm = ''" icon small retain-focus-on-click><v-icon color="fontBody" size="20">mdi-close</v-icon></v-btn>
              </v-row>
            </v-toolbar-items>
          </v-toolbar>
        </v-card-title>
        <v-divider />
        <v-card-text class="py-4 px-5 fontBody--text">
          <v-row class="pt-2 pb-2" align="center">
            <v-col cols="3">
              <img :src="require(`@/assets/backgrounds/modal-document-confirm.svg`)" :height="128" />
            </v-col>
            <v-col class="pr-3 pl-1">
              <span class="d-block text-body-1">Recuerde, que está confirmación permite Interrumpir el proceso y no podrá volver atrás.</span>
              <span class="d-block text-body-1 mt-4 mb-3">Escribe <span class="font-weight-bold">“confirmar”</span> para continuar.</span>
              <v-text-field v-model="confirm" outlined single-line maxlength="9" dense hide-details />
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider />
        <v-card-actions class="py-2 px-5">
          <v-spacer/>
          <v-btn @click="dialogDelete=false, confirm = ''" outlined>Cancelar</v-btn>
          <v-btn color="primary" @click="destroy" :disabled="confirm.toLowerCase() !== 'confirmar'">Confirmar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- end dialog confirm delete -->

    <!-- dialog pdf -->
    <v-dialog v-model="modalRender" width="700" persistent scrollable no-click-animation overlay-color="overlay">
      <render-pdf :basicRender="true" :documentType="current.type | nameDocumentsType" :number="current.number" :loaderRender="loaderRender" :renderUrl="renderUrl" @close="modalRender=false" :key="resetPDF" />
    </v-dialog>
    <!-- end dialog pdf -->
    </v-row>
  </template>
  <script>
  import { mapState } from 'vuex'
  import moment from 'moment'
  import { saveAs } from 'file-saver'
  import SkeletonList from '@/components/commons/skeletonLoader/SkeletonList'
  import MiniHeader from '@/components/commons/MiniHeader'
  import VEmptyState from '@/components/commons/VEmptyState'
  import ChipStatus from '@/components/commons/ChipStatus'
  import GenericViewMixin from '@/mixins/GenericViewMixin'
  import RenderViewMixin from '@/mixins/RenderViewMixin'
  import ListViewMixin from '@/mixins/ListViewMixin'
  import VTableHeaders from '@/components/VTable/VTableHeaders'
  import VMainPagination from '@/components/commons/VMainPagination'
  import RenderPdf from '@/components/files/RenderPdf'
  import DocumentRowDetail from '@/modules/documents/batch/components/DocumentRowDetail'

  export default {
    mixins: [
      GenericViewMixin, RenderViewMixin, ListViewMixin
    ],
    components: {
      SkeletonList,
      VEmptyState,
      MiniHeader,
      ChipStatus,
      VTableHeaders,
      VMainPagination,
      DocumentRowDetail,
      RenderPdf
    },
    data: () => ({
      countBatch: false,
      resetPDF: 0,
      dialogExport: false,
      extensionFile: 'xlsx',
      hiddenTimeLeft: false,
      totalTime: 0,
      timeLeftInterval: 0,
      time: null,
      timeLeft: null,
      dialogDelete: false,
      loadingTransition: false,
      scroll: 0,
      index: 0,
      dialogDetail: false,
      dialogRandomReviewPDF: false,
      confirm: '',
      dialogProcess: false,
      loading: false,
      loadingBatchDocument: false,
      loadingNextStage: false,
      instance: {},
      current: {},
      stepperList: [
        {
          status: ['CRE'],
          title: 'Procesando',
          icon: 'upload',
          color: 'yellowLight'
        },
        {
          status: ['PEN'],
          title: 'Procesado',
          icon: 'cog',
          color: 'yellowLight',
          ms: 200
        },
        {
          status: ['CON'],
          title: 'Confirmado',
          icon: 'bank',
          ms: 200,
          color: 'yellowLight',
          alert: 'Durante esta etapa, los <span class="font-weight-bold">documentos subidos, son timbrados y firmados.</span>'
        },
        {
          status: ['COM'],
          title: 'Completado',
          icon: 'check',
          color: 'lightBlue',
          ms: 2000,
          alert: 'Durante esta etapa, <span class="font-weight-bold">cada documento</span> creado a partir del batch <span class="font-weight-bold">se envía al SII.</span> '
        },
        {
          status: ['REJ'],
          title: 'Interrumpido',
          icon: 'close',
          color: 'redLight',
          ms: 500,
          alert: 'Durante esta etapa, <span class="font-weight-bold">cada documento</span> creado a partir del batch, <span class="font-weight-bold">se envía a los emails pertinentes.</span>'
        },
        {
          status: ['FAI'],
          title: 'Fallido',
          icon: 'close',
          color: 'redLight'
        }
      ],
      headers: [
        { text: 'Cliente', value: 'customer_name' },
        { text: 'Tipo de documento', value: 'type' },
        { text: 'Numeración#', value: 'number', align: 'end' },
        { text: 'Fecha', value: 'date', align: 'end' },
        { text: 'Estado', value: 'status', width: 180 },
        { text: 'Total', value: 'amount_total', align: 'end' },
        { text: '', value: 'actions', align: 'end'}
      ],
      batchList: [],
      progress: {
        value: 0,
        query: false,
        show: true,
        interval: 0
      },
      transition: [],
      breadcrumbs: {
        main: 'Emisión',
        children: [
          {
            text: 'Emisión por lotes',
            route: 'DocumentsBatchListCreate'
          }
        ]
      }
     }),
    watch: {
      'instance.status': {
        handler (val) {
          if (val !== 'pending' && val.search(/ing/) !== -1) this.getTransition()
        }
      },
      loadingTransition: {
        handler (val) {
          if (!val) {
            this.getTime()
          }
        },
        deep: true
      }
    },
    created () {
      this.breadcrumbs.children.push({ text1: `Detalle ${this.$route.params.id}`})
      this.retrieve()
    },
    computed: {
      ...mapState({
        countDocuments: state => state.documents.documentsCount,
        // countBatch: state => state.batch.batches_documentsCount,
        batchDetail: state => state.batch.batchesDetail,
        documentsList: state => state.documents.documentsList
      }),
      number () {
        return this.$store.state?.auth?.account?.country === 'PE' ? `${this.current.prefix} - ${this.current.number}` : this.current.number
      },
      stepStyle () {
        return (step, index) => {
          if (index >= 0 && index <= this.stepperList.findIndex((item) => item.status.includes(this.instance.status))) return 'background-color: var(--light-blue-primary)'
          return 'border: 2px solid var(--light-blue-grey); background: var(--light-white);'
        }
      },
      stepIconColor () {
        return (step, index) => {
          if (index >= 0 && index <= this.stepperList.findIndex((item) => item.status.includes(this.instance.status))) return 'white'
          return 'var(--light-blue-grey)'
        }
      },
      getTimeLeft () {
        const hours = this.timeLeft ? parseInt(moment.utc(this.timeLeft).format('HH')) : 0
        const minutes = this.timeLeft ? parseInt(moment.utc(this.timeLeft).format('mm')) : 0
        const seconds = this.timeLeft ? parseInt(moment.utc(this.timeLeft).format('ss')) : 0
        if (hours === 0 && (minutes > 0 || minutes <= 59) && minutes > 0) return `${minutes}:${seconds} ${minutes === 1 ? 'minuto' : 'minutos'}`
        if (minutes > 0 || minutes < 60) return `00:${seconds} ${seconds === 1 ? 'segundo' : 'segundos'}`
        return `${hours}:${minutes}:${seconds} ${hours === 1 ? 'hora' : 'horas'}`
      },
      getProgressTimeLeft () {
        return this.timeLeft ? 100 - ((this.timeLeft * 100) / this.totalTime) : 100
      },
      currentHeader () {
        return this.instance.status === 'CRE' ? this.headers.filter(({value}) => value !== 'status') : this.headers
      },
      currentStepper () {
        if (this.instance.status === 'REJ') return this.stepperList.filter(({status}) => status.find((item) => !['FAI', 'CON', 'COM'].includes(item)))
        if (this.instance.status === 'FAI') return this.stepperList.filter(({status}) => status.find((item) => !['REJ', 'CON', 'COM'].includes(item)))
        return this.stepperList.filter(({status}) => status.find((item) => !['FAI', 'REJ'].includes(item)))
      },
      setTooltip () {
        return (list = {}) => {
          let array = []
          if (list?.length) {
            list.forEach((item) => {
              array = [...array, `${item} &&`]
            })
          }
          return array.toString().replace(/[&&,]/g, '<br>')
        }
      },
      heightTable () {
        if (this.$vuetify.breakpoint.height < 900) return 'calc(100vh - 350px)'
        else if (this.documentsList.length < 20) return 'auto'
        return 'calc(100vh - 330px)'
      }
    },
    methods: {
      async getTime () {
        const { ms } = this.stepperList.find((item) => item.status.includes(this.instance.status))
        this.progress.interval = ms ? ((this.instance.count / 10) * ms) / this.instance.count : ''

        let current = this.stepperList.find(({status}) => status.includes(this.instance.status)).status
       /* eslint-disable */
        let transitionCurrent = this.transition.find(({status_to}) => current.includes(status_to))

        if (!!transitionCurrent?.end) {
          const end = moment(transitionCurrent.end).format()
          const start = moment(end).add(this.progress.interval, 's').format()
          const duration = moment.duration((moment(start).diff(moment())))
          const sec = duration.seconds()
          this.timeLeft = sec + (this.progress.interval * 1000)
          this.totalTime = sec + (this.progress.interval * 1000) // progress
          if (sec > 0) {
            this.timeLeftInterval = setInterval(() => {
              if (this.timeLeft >= 1000) {
                this.timeLeft -= 1000
              } else {
                this.detenerTemporizador()
              }
            }, 1000)
          } else {
            this.detenerTemporizador()
          }
        }
      },
      getTransition () {
        this.loadingTransition = true
        this.$store.dispatch('batch/GET', {
          resource: 'batch_transition/',
          query: {
            batch: this.$route.params.id
          }
        })
        .then((resp) => {
          this.transition = resp.data.results
        })
        .finally(() => {
          this.loadingTransition = false
        })
      },
      goTo (id = '') {
        this.$store.commit('base/SET_QUERY', this.$route.query)
        this.$router.push({ name: 'DocumentsRetrieve', params: { id: id, ...this.currentParams }})
      },
      detenerTemporizador () {
        clearInterval(this.timeLeftInterval)
        this.timeLeft = 0
        this.$store.dispatch('batch/RETRIEVE', {
        resource: 'batch',
          id: this.$route.params.id
        })
        .then(() => {
          this.instance = {...this.batchDetail}
          this.getDocumentByBatch()
        })
      },
      onScroll (e) {
        this.scroll = e.target?.scrollingElement?.scrollTop ?? 0
      },
      handlerDetail (item = {}) {
        if (['processed', 'declared', 'distributing', 'distributed', 'finalized'].includes(this.instance.status)) {

        } else {
          this.current = item
          this.dialogDetail = true
        }
      },
      retrieve () {
        this.loading = true
        this.$store.dispatch('batch/RETRIEVE', {
          resource: 'generation/batches',
          id: this.$route.params.id
        })
        .then(() => {
          this.instance = {...this.batchDetail}
          this.getDocumentByBatch()
          this.loading = false
        })
      },
      handleConfirm () {
        this.nextStage()
        this.dialogProcess = false
        this.confirm = ''
      },
      nextStage () {
        this.loadingNextStage = true
        this.$store.dispatch('batch/LIST', {
          resource: `generation/batches/${this.$route.params.id}/confirm`,
          loader: false
        })
        .then((resp) => {
          this.instance.status = resp.data.status
          this.getDocumentByBatch()
        })
        .catch((error) => {
        this.$dialog.message.error(error.response.data.message)
        })
      },
      getDocumentByBatch () {
        this.loadingBatchDocument = true
        this.$store.dispatch('documents/LIST', {
          resource: 'documents',
          query: {
            batch_id: this.$route.params.id,
            ...this.$route.query
          }
        })
        .finally(() => {
          this.loadingBatchDocument = false
        })
      },
      createExport () {
        this.$store.dispatch('batch/LIST', {
          resource: `batch/${this.$route.params.id}/export_error`,
          query: {
            extension: this.extensionFile
          },
          loader: false,
          responseType: 'arraybuffer'
        })
        .then((response) => {
          const content = Buffer.from(response.data, 'base64')
          saveAs(new Blob([content], {type: 'application/xlsx'}), 'excel de errores.xlsx')
          this.$dialog.message.info('La exportación se realizó con éxito')
        })
        .catch((response) => {
          if (response.toString().search('400') !== -1) {
            this.$dialog.message.info('No hay errores en el proceso del batch')
          } else this.$dialog.message.error('Ha ocurrido un error en la exportación')
        })
        .finally(() => {
          this.dialogExport = false
        })
      },
      handlePDF (type = 'next') {
        this.index = type === 'next' ? this.index + 1 : this.index - 1
        this.handleRandomPDF()
      },
      handleRandomPDF () {
        this.dialogRandomReviewPDF = true
        this.randomReviewPDF()
      },
      randomReviewPDF () {
        this.renderDocument('batch', 'batch_documents', 'pdf', this.instance.random_preview[this.index])
      },
      destroy () {
        this.$store.dispatch('batch/LIST', {
          resource: `generation/batches/${this.$route.params.id}/reject`
        })
        .then(() => {
          this.$dialog.message.info('Se ha interrumpido el proceso con éxito')
          this.retrieve()
        })
        .catch((error) => {
          this.$dialog.message.error(error.response.data.message)
        })
        .finally(() => {
          this.dialogDelete = false
        })
      }
    }
  }
  </script>
  <style>
  .line-stepper {
    position: relative !important;
    top: -42px;
    z-index: 0;
    width: 80px;
    left: 70px;
  }
  .spinner {
    animation: rotation 2s linear infinite;
  }

  @keyframes rotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
  </style>