<template>
  <div>
    <v-tooltip v-if="!isActivePopover" color="bgSearch" right transition="scale-transition" :max-width="280" :open-on-hover="isTooltip && !!activeStatus.detail && activeStatus.detail !== activeStatus.summary">
      <template v-slot:activator="{on}">
        <v-chip v-if="activeStatus" v-on="isTooltip && activeStatus.detail && activeStatus.detail !== activeStatus.summary ? on : false" :color="activeStatus.color" small label :class="{'px-2' : activeShortMode.includes($route.name)}">
            <span class="d-inline-block text-truncate font-weight-semibold" style="max-width: 150px;" v-if="routeList.includes($route.name) || showDetail">
              <template v-if="['DocumentsList', 'SummariesList', 'SummariesRetrieve', 'PurchaseList', 'DocumentsRetrieve', 'DocumentsBatchRetrieve', 'PurchaseRetrieve'].includes($route.name)">
              <span>{{ $route.name === 'DocumentsRetrieve' ? activeStatus.detail : `${activeStatus.summary} ${activeStatus.id === 'OBS' ? 'con reparo' : ''}` }}</span>
            </template>
            </span>
          <v-icon :right="!activeShortMode.includes($route.name)">{{activeStatus.icon}}</v-icon>
        </v-chip>
      </template>
      <div>
        <span class="d-block px-3 py-2" v-if="detail" v-html="detail"></span>
        <span class="d-block px-3 py-2" v-else-if="(activeStatus || {}).detail === 'Enviado a SII'">{{ $t('generals.Enviado a SII') }}</span>
        <span class="d-block px-3 py-2" v-else-if="!['PurchaseList','PurchaseRetrieve'].includes($route.name)">{{activeStatus.detail}}</span>
      </div>
    </v-tooltip>
    <v-menu class="list-active-draw" v-else right :close-delay="100" :open-on-click="false" :open-on-hover="!
    !detail" :open-delay="10" :nudge-right="20" offset-x max-width="300">
      <template v-slot:activator="{ on, attrs }">
        <v-chip v-bind="attrs" v-on="detail !== activeStatus.summary ? on : false" :color="activeStatus.color" small label :class="{'px-2' : activeShortMode.includes($route.name)}">
          <span class="d-inline-block text-truncate" style="max-width: 150px;" v-if="routeList.includes($route.name) || showDetail">
            <template v-if="['DocumentsList', 'SummariesList', 'SummariesRetrieve', 'PurchaseList', 'DocumentsRetrieve', 'DocumentsBatchRetrieve', 'PurchaseRetrieve'].includes($route.name)">
            <span class="font-weight-semibold">{{ $route.name === 'DocumentsRetrieve' ? activeStatus.detail : `${activeStatus.summary} ${activeStatus.id === 'OBS' ? 'con reparo' : ''}` }}</span>
          </template>
          </span>
          <v-icon :right="!activeShortMode.includes($route.name)">{{activeStatus.icon}}</v-icon>
        </v-chip>
      </template>
      <v-list class="pa-0">
        <span class="d-block caption px-3" style="line-height: 1.2" v-if="detail" v-html="detail || activeStatus.detail"></span>
      </v-list>
    </v-menu>
  </div>
</template>
<script>
import documentStatuses from '@/collections/documentStatuses'
import purchaseStatuses from '@/collections/purchaseStatuses'
import summariesStatuses from '@/collections/summariesStatuses'

export default {
  props: {
    status: {
      type: null,
      required: true
    },
    isTooltip: {
      type: Boolean,
      deault: false
    },
    showDetail: {
      type: Boolean,
      deault: false
    },
    detail: {
      type: null
    },
    isActivePopover: {
      type: Boolean,
      default: false
    },
    type: {
      type: String,
      required: ''
    }
  },
  data: () => ({
    activeShortMode: ['BranchRetrieveUpdate', 'PosRetrieveUpdate', 'SupplierRetrieveUpdate', 'CustomerRetrieveUpdate'],
    documentStatuses: documentStatuses,
    purchaseStatuses: purchaseStatuses,
    routeList: ['DocumentsList', 'SummariesList', 'SummariesRetrieve', 'PurchaseList', 'DocumentsBatchRetrieve', 'DocumentsBatchListCreate', 'PurchaseRetrieve']
  }),
  computed: {
    activeStatus () {
      return this.currentStatusesList().find(s => s.id === this.status) ?? this.status
    }
  },
  mounted () {
    this.currentStatusesList()
  },
  methods: {
    currentStatusesList () {
      if (['SummariesList', 'SummariesRetrieve'].includes(this.$route.name)) return summariesStatuses
      if (['DocumentsList', 'DocumentsRetrieve', 'BranchRetrieveUpdate', 'PosRetrieveUpdate', 'CustomerRetrieveUpdate', 'DocumentsBatchRetrieve'].includes(this.$route.name) || this.type !== 'purchases') return this.documentStatuses?.filter(({countries}) => countries.includes(this.$store?.state?.auth?.account?.country)) ?? []
      return this.purchaseStatuses
    }
  }
}
</script>