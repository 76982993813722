export default [
  /* AXT */
  {
    id: "CRE",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "MRT",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "ART",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "APR",
    code: 13,
    detail: 'Aceptado',
    color: 'lightBlue',
    summary: 'Aceptado',
    icon: 'mdi-check',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "OBS",
    code: 13,
    detail: 'Aceptado con reparo',
    color: 'purpleLight',
    summary: 'Aceptado',
    icon: 'mdi-check',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "SNT",
    code: 11,
    detail: 'Pendiente',
    color: 'blueGreyLighten',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "FAI",
    code: 11,
    detail: 'Pendiente',
    color: 'blueGreyLighten',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "REJ",
    code: 7,
    detail: 'Rechazado',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "DUP",
    code: 7,
    detail: 'Rechazado',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
      id: "VOI",
      code: 16,
      detail: 'Anulado',
      color: 'greyMedium',
      summary: 'Anulado',
      icon: 'mdi-file-document-remove',
      isAX: true,
      countries: ['CL', 'PE']
  },
  {
    id: "INV",
    code: 17,
    detail: 'No confirmado',
    color: 'blueGreyLighten',
    summary: 'No confirmado',
    isAX: true,
    icon: 'mdi-file-clock',
    countries: ['PE']
  },
  {
    id: "PRE",
    code: 18,
    detail: 'Por confirmar',
    color: 'lightBlue',
    isAX: true,
    summary: 'Por confirmar',
    icon: 'mdi-file-check',
    countries: ['PE']
  },
  /* END AXT */

  {
    id: "ACT",
    code: 13,
    detail: 'Aceptado',
    color: 'lightBlue',
    summary: 'Aceptado',
    icon: 'mdi-check',
    countries: ['CL', 'PE']
  },
  {
    id: "EPR",
    code: 8,
    detail: 'Envío procesado',
    color: 'lightBlue',
    summary: 'Aceptado',
    icon: 'mdi-check',
    countries: ['CL']
  },
  {
    id: "SOK",
    code: 2,
    detail: 'Schema validado',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    countries: ['CL']
  },
  {
    id: "FOK",
    code: 5,
    detail: 'Firma de envío validada',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    countries: ['CL']
  },
  {
    id: "PDR",
    code: 6,
    detail: 'Envío en proceso',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    countries: ['CL']
  },
  {
    id: "REV",
    code: 15,
    detail: 'En revisión',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    countries: ['CL']
  },
  {
    id: "INI",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    countries: ['CL', 'PE']
  },
  {
    id: "IMP",
    code: 10,
    detail: 'Enviado a SII',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    // summary: 'Aceptado',
    // icon: 'mdi-check',
    countries: ['CL', 'PE']
  },
  {
    id: "CRT",
    code: 3,
    detail: 'Carátula OK',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    countries: ['CL']
  },
  {
    id: "SPR",
    code: 11,
    detail: 'Pendiente',
    color: 'blueGreyLighten',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    countries: ['PE']
  },
  {
    id: "RFR",
    code: 4,
    detail: 'Rechazado por parámetros de entrada incompletos',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    countries: ['CL']
  },
  {
    id: "RCT",
    code: 7,
    detail: 'Rechazado por error en carátula',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    countries: ['CL', 'PE']
  },
  {
    id: "RCP",
    code: 12,
    detail: 'Rechazado',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    countries: ['PE']
  },
  {
    id: "RCH",
    code: 14,
    detail: 'Rechazado',
    summary: 'Rechazado',
    color: 'redLight',
    icon: 'mdi-close',
    countries: ['CL']
  },
  {
    id: "RSC",
    code: 1,
    detail: 'Rechazado por error en schema',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    countries: ['CL']
  }
  // {
  //   id: "NUL",
  //   code: 16,
  //   detail: 'Anulado',
  //   color: 'greyMedium',
  //   summary: 'Anulado',
  //   icon: 'mdi-file-document-remove',
  //   countries: ['CL', 'PE']
  // }
]