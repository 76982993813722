export default [
  {
    id: "CRE",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "MRT",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "ART",
    code: 9,
    detail: 'Procesando',
    color: 'yellowLight',
    summary: 'Procesando',
    icon: 'mdi-sync',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "APR",
    code: 13,
    detail: 'Aceptado',
    color: 'lightBlue',
    summary: 'Aceptado',
    icon: 'mdi-check',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "OBS",
    code: 13,
    detail: 'Aceptado con reparo',
    color: 'purpleLight',
    summary: 'Aceptado',
    icon: 'mdi-check',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "SNT",
    code: 11,
    detail: 'Pendiente',
    color: 'blueGreyLighten',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "FAI",
    code: 11,
    detail: 'Pendiente',
    color: 'blueGreyLighten',
    summary: 'Pendiente',
    icon: 'mdi-clock',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "REJ",
    code: 7,
    detail: 'Rechazado',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    isAX: true,
    countries: ['CL', 'PE']
  },
  {
    id: "DUP",
    code: 7,
    detail: 'Rechazado',
    color: 'redLight',
    summary: 'Rechazado',
    icon: 'mdi-close',
    isAX: true,
    countries: ['CL', 'PE']
  }
//   {
//     id: "VOI",
//     code: 16,
//     detail: 'Anulado',
//     color: 'greyMedium',
//     summary: 'Anulado',
//     icon: 'mdi-file-document-remove',
//     isAX: true,
//     countries: ['CL', 'PE']
// }
]